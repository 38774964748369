<template>
  <div>
    <CCard class="col-lg-6 col-sm-12">
      <CCardHeader>
        User Info
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CInput
              prepend="Username"
              type="text"
              :value.sync="username"
            >
            </CInput>
          </CCol>
          <CCol sm="12">
            <CInput
              prepend="Mail"
              type="text"
              :value.sync="mail"
            >
            </CInput>
          </CCol>
          <CCol sm="12">
            <CInput
              placeholder="If you don't want to change,leave it blank"
              prepend="Password"
              type="password"
              :value.sync="password"
            >
            </CInput>
          </CCol>
          <CCol sm="12">
            <CInput
              placeholder="If you don't want to change,leave it blank"
              prepend="Password Verify"
              type="password"
              :value.sync="repassword"
            >
            </CInput>
          </CCol>
          <CCol sm="12">
            <CButton
              color="primary"
              square
              @click="changeInfo"
            >
              Submit
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {

    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      username: null,
      mail: null,
      password: null,
      repassword: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/info", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.username = response.data.username;
            this.mail = response.data.mail;
          }
        });
    },
    changeInfo: function() {
      this.$axios.post("user/info", {
        username: this.username,
        mail: this.mail,
        password: this.password,
        repassword: this.repassword
      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
        });
    }
  }
};
</script>